@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  background: #f4f5f9;
  color: #22282e;
  font-family: "Inter", sans-serif;
  height: 100vh;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c1782;
  border-radius: 50px;
  transition: ease-in-out 0.3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e0e5f;
}
#root {
  height: 100%;
}
img {
  max-width: 100%;
}
.main-panel {
  display: flex;
}
.fm-panel {
  flex: 0 0 280px;
  background: #fff;
  height: 100vh;
  position: fixed;
  width: 280px;
  padding: 0px;
  overflow-x: auto;
}
.panel-content {
  flex: 0 0 calc(100% - 280px);
  margin-left: 280px;
  max-width: calc(100% - 280px);
  padding-bottom: 30px;
}
.logo-sidebar {
  padding: 15px 24px 45px;
  text-align: center;
}

.overview-cont {
  padding: 65px 0;
}

.overview-cont h3 {
  font-size: 36px;
  font-weight: 700;
  color: #22282e;
  margin-bottom: 30px;
}
.my-account-wrap {
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: solid 1px #ededfa;
}

.user-img {
  height: 52px;
  width: 52px;
  margin: 0 auto 15px;
  border-radius: 50%;
}

.user-cont h5 {
  font-size: 14px;
  color: #22282e;
  font-weight: 600;
}

.user-cont a {
  color: #2e1885;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.sidebar-menu ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.sidebar-menu ul li a {
  background: #fff;
  padding: 13px 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #22282e;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.5s;
}
.menu-ic {
  line-height: 1;
  margin-right: 15px;
}
.sidebar-menu ul li a.active {
  background: #f5f6ff;
  font-weight: 600;
}

.fm-panel::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.fm-panel::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.fm-panel::-webkit-scrollbar-thumb {
  background-color: #2e1983;
  border: 0;
}
.dashboard-job {
  margin-top: 25px;
}

.main-title {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.main-title h5 {
  font-size: 20px;
  font-weight: 700;
  color: #22282e;
  margin-bottom: 0;
}

.main-title span {
  line-height: 1;
  margin-right: 10px;
}

.col_5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.card.booking-bg {
  background: #fe5f55;
  border-color: #fe5f55;
}

.card.inquiries-bg {
  background: #2e1885;
  border-color: #2e1885;
}

.card.inquiries-bg p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}

.card.inquiries-bg h3 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}

.card {
  border-radius: 8px;
}

.card-body {
  padding: 12px 20px;
}

.card.booking-bg p {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}
.card.booking-bg h3 {
  font-size: 24px;
  margin-bottom: 0;
}
.job-topbar h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: #22282e;
}
.job-bottom-bar {
  padding-top: 20px;
}
.job-topbar {
  padding-bottom: 20px;
}
.job-bottom-bar h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.job-bottom-bar p {
  margin-bottom: 0;
  font-size: 14px;
  color: #22282e;
}
.space-sec {
  margin: 24px 0;
}
.card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 0 !important;
}
.due-wrap {
  display: flex;
  align-items: start;
}
.date-cls {
  background: #f1f3f6;
  border-radius: 8px;
  padding: 5px 15px;
  margin-right: 32px;
}
.date-cls p {
  margin-bottom: 0;
  font-size: 14px;
  color: #495867;
  font-weight: 500;
}

.event-dtl h5 {
  font-size: 16px;
  font-weight: 600;
  color: #2c3542;
  margin-bottom: 0;
}

.event-dtl p {
  margin-bottom: 0;
  color: #2c3542;
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}
.due-next-list .card {
  margin-bottom: 12px;
  box-shadow: none;
  border: 0;
}
.active .menu-ic svg path {
  stroke: #2e1983;
}

.menu-ic svg path {
  stroke: #22282e;
}
a {
  cursor: pointer;
}
.inner-title {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
}
.add-new-wrap {
  margin-left: auto;
}
.inner-title .nav {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px !important;
  border: 0 !important;
  margin: 0;
}
.inner-title .nav .nav-link {
  border: 0 !important;
  padding: 12px 30px;
  border-radius: 8px !important;
  margin: 0 !important;
  color: #22282e !important;
  font-weight: 600;
  font-size: 16px;
  min-width: 180px;
}
.inner-title .nav .nav-link.active {
  background: #2e1983;
  color: #fff !important;
}
.btn_primary {
  background: #2e1885;
  border-radius: 28px;
  color: #fff;
  border-color: #2e1885 !important;
  padding: 10px 30px;
}
.btn_primary svg {
  margin-right: 5px;
}
.booking-card {
  padding: 15px 15px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 15px;
}
.booking-main-card.booking-card {
  display: flex;
}
.booking-left {
  display: flex;
  flex: 0 0 calc(100% - 280px);
  max-width: calc(100% - 280px);
  padding-right: 25px;
}

.booking-cont h3 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
}
.msg-ic {
  margin-right: 10px;
}
.booking-cont p {
  font-weight: 400;
  font-size: 14px;
  color: #22282e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  margin-bottom: 0;
  max-width: 600px;
}
.booking-notification {
  flex: 0 0 280px;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.booking-cont {
  width: calc(100% - 30px);
}
.booking-notifi-cont h5 {
  font-size: 15px;
  margin-bottom: 0;
}
.booking-notifi-cont {
  text-align: right;
}
.tag {
  padding: 4px 16px;
  width: 158px;
  background: #f0f1fa;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
  margin-left: auto;
}
.notifi-dot {
  padding-left: 10px;
}
.booking-card a {
  display: flex;
  color: #22282e;
  text-decoration: none;
}
.btn_primary:hover {
  background: #160752;
  color: #fff;
}
.back-wrap a {
  color: #2e1885;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}
.client-dtl .card {
  padding: 15px;
  height: 100%;
}
.client-dtl h4 {
  font-weight: 700;
  font-size: 18px;
  color: #22282e;
  margin-bottom: 8px;
}
.client-info h5 {
  font-size: 16px;
  color: #22282e;
  margin-bottom: 2px;
  font-weight: 600;
}
.client-info p {
  margin-bottom: 0;
  font-size: 14px;
  color: #22282e;
}
.client-info {
  margin-bottom: 10px;
}
.client-dtl .card .row [class*="col-"]:last-child .client-info {
  margin-bottom: 0;
}
.client-dtl {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.text-right {
  text-align: right;
}
.card_header {
  display: flex;
  justify-content: space-between;
}

.card_header h5 {
  font-size: 18px;
  color: #2e1885;
  font-weight: 500;
  margin-bottom: 0;
}

.card_header p {
  font-size: 14px;
  margin-bottom: 0;
  color: #22282e;
}

.card_header {
  border-bottom: solid 1px #d2d2e8;
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.client-info-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.client-dtl.event-cls {
  height: unset;
}
.file_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.file_wrap svg {
  margin-right: 15px;
}
.file_wrap h6,
.file_wrap a {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* text-decoration-line: underline; */
  color: #00a3ff;
}
.file_wrap:last-child {
  margin-bottom: 0;
}
.modal-header {
  border: 0;
  position: absolute;
  right: 15px;
  z-index: 1111;
  padding: 0;
  top: 20px;
}

button.btn-close {
  background-color: #f2f3ff;
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  justify-content: center;
  border-radius: 50%;
  opacity: 1;
  margin:0 !important;
}

.contract_modal {
  padding-top: 15px;
}

.contract_modal h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #121212;
}

.single-contract {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 12px 0;
}
.contact_cont h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #22282e;
}

.contact_cont {
  margin-left: 15px;
  margin-bottom: -5px;
}

.single-contract:last-child {
  margin-bottom: 0;
}
.single-contract a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
a,
button {
  cursor: pointer !important;
}
.contract_launch {
  text-align: center;
}
.contract_launch h3 {
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
}
.upcoming-job-wrap .card {
  height: 100%;
}
.client-dtl .card .client-info-main:last-child .client-info {
  margin-bottom: 0;
}
.due-next-list .card:last-child {
  margin-bottom: 0;
}
.mobile-menu {
  display: none;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background: #160752;
  color: #fff !important;
}
.pro-card.card {
  padding: 15px;
  margin-bottom: 15px;
  height: calc(100% - 15px);
}
.tab-inner-cont .row {
  margin: 0 -7.5px;
}
.tab-inner-cont .row [class*="col"] {
  padding: 0 7.5px;
}
.pro-tit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}
.pro-tit h5 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  color: #22282e;
}
.pro-address {
  display: flex;
  align-items: center;
}
.pro-address p {
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #22282e;
}
.map-ic {
  line-height: 0;
}
.date-session {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.date-wrap {
  display: flex;
}
.date-wrap p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #22282e;
  margin-left: 5px;
}
.calendar {
  line-height: 1.3;
}
.session-wrap p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #22282e;
}
.filter-btn input {
  border-radius: 8px;
  border: solid 1px #000000;
  padding: 10px 30px;
  appearance: none !important;
}
.due-date p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #2e1885;
  display: inline-flex;
  border: solid 1.5px #2e1a81;
  padding: 8px 30px;
  border-radius: 8px;
  margin-top: 10px;
}
.pro-card.card a {
  text-decoration: none;
}
.signle-client-info ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 5px;
}
.signle-client-info ul li {
  display: flex;
  margin-bottom: 4px;
}
.info-label {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.info-label span {
  color: #8c8c8c;
  font-size: 14px;
  margin-right: 6px;
  line-height: 1;
}
.signle-client-info ul li:last-child {
  margin-bottom: 0;
}
.signle-client-info {
  padding-right: 30px;
}
.signle-client-info ul li a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
.signle-client-info ul li:last-child {
  margin-bottom: 0;
}
.project-client-wrap .client-info {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signle-client-info:last-child {
  padding-right: 0;
}
.checklist-dtl .card button {
  background: #fef3f3;
  border: 1px solid #fe5f55;
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: unset;
  padding: 16px 24px;
}
.checklist-status {
  margin-left: auto;
  display: flex;
}
.checklist-title h6 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  line-height: 19px;
}
.checklist-status p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 19px;
  /* margin-right: 10px; */
}
.session-header {
  display: flex;
  justify-content: space-between;
}
.session-header h5 {
  font-weight: 500;
  font-size: 15px;
  color: #121212;
  margin-bottom: 16px;
}

.date-time-wrap {
  display: flex;
}
.date-left {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.date p {
  margin-bottom: 0;
  font-size: 14px;
  margin-left: 5px;
}
.time-right {
  display: flex;
  align-items: center;
}
.time-right p {
  font-size: 14px;
  font-weight: 400;
}
.location-wrap {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.location-wrap a {
  margin-left: 5px;
  text-decoration: none;
  color: #0085ff;
  font-size: 14px;
  font-weight: 500;
}
.additional-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  margin-bottom: 8px;
}

.additional-info {
  margin-top: 16px;
}
.additional-info p {
  font-weight: 400;
  font-size: 16px;
  color: #121212;
  margin-bottom: 0;
}
.team-info {
  margin-top: 16px;
}
.team-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}
.team-inner ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: wrap;
  gap: 8px;
}

.team-inner ul li {
  /* padding: 0 5px; */
}
.team-img {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  background: #ddd;
  overflow: hidden;
  border-radius: 50%;
}
.team-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.mark-complate {
  margin-top: 24px;
}
.add_btn {
  border: 0;
  background: #959595;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  width: 18px;
  background-color: #f1f3f6;
  top: 4px;
  border: 1px solid #22282e;
  border-radius: 4px;
}
.custom-checkbox input:checked ~ .checkmark {
  background-color: #fe5f55;
  border-color: #fe5e55;
}
span.drag-op {
  margin-right: 12px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.custom-checkbox .checkmark:after {
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
  left: 5px;
}
.checklist-dtl .card button:hover,
.checklist-dtl .card button:focus {
  background: #fef3f3;
  border: 1px solid #fe5f55;
}

.checklist-tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.checklist-tit h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
  line-height: 1;
}
.checklist-tit span {
  padding: 4px 16px;
  border: 1px solid #d2d2e8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #22282e;
}
.modal-header .btn-close {
  padding: 0;
}
.checklist-wrap ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.checklist-wrap ul li {
  margin-bottom: 10px;
  padding: 12px 16px;
  background: #f1f3f6;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #22282e;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #22282e;
}

.form-control::placeholder {
  color: #22282e;
}
.session_dtl ul {
  margin-top: 20px;
  background: #ffffff;
  /* One Shadow */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-left: 0;
  margin-bottom: 25px;
  list-style: none;
}

.session_dtl ul li {
  padding: 12px 15px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.session_dtl ul li.selected {
  background: #f1f3f6;
  color: #2e1983;
}

.checklist-modal .modal-dialog {
  max-width: 700px;
}
.form-group {
  margin-bottom: 30px;
}
label {
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #22282e;
  margin-bottom: 5px;
}
.form-control {
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #d2d2e8;
  border-radius: 8px;
  padding: 10px 15px;
  outline: 0 !important;
  appearance: auto;
  box-shadow: none !important;
}
.save-btn {
  display: flex;
  flex-direction: column;
}
.unassign-team-member {
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #e63131;
}
.session-time input {
  text-align: center;
}
.recieved-today {
  display: flex;
  align-items: center;
}

.recieved-today ul {
  display: flex;
  margin: 0 !important;
  padding-left: 20px;
  gap: 20px;
}
.recieved-today h5 {
  margin-bottom: 0;
}
.client-info ul {
  margin-bottom: 0;
  padding-left: 25px;
}
.client-info ul li {
  font-size: 14px;
  margin-top: 5px;
  color: #22282e;
}
.recieved-today ul li {
  margin: 0;
}
.inner-title h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: #2e1983;
}
.pro-card.card.team-card a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.user-img-bg {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d7f4;
  border-radius: 50%;
  overflow: hidden;
}
.user-img-bg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.team-user-dtl p {
  padding-left: 0;
  margin-left: 0;
}
.team-user-dtl {
  padding-left: 15px;
}
.team-user-dtl .pro-address {
  display: block;
}
.team-user-dtl .pro-tit h5 {
  margin-bottom: 0;
}
.team-user-dtl .pro-tit {
  display: block;
  margin-bottom: 0;
}
.user-dot {
  margin-left: auto;
}
.file_input input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.file_input span {
  border-bottom: 1px solid #2e1983;
  border-radius: 0;
  padding: 0;
  font-size: 15px;
  text-align: center;
  color: #2e1983;
}
.file_input {
  position: relative;
}
.team-member-profile {
  margin-bottom: 25px;
  text-align: center;
}
.team-member-profile .profile-img {
  height: 72px;
  width: 72px;
  margin: 25px auto 20px;
  border-radius: 50%;
  overflow: hidden;
}
.team-member-profile .profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.team-dtl h4 {
  font-weight: 700;
  font-size: 18px;
  color: #22282e;
  margin-bottom: 12px;
}
.team-dtl .card {
  padding: 15px;
}
.team-dtl .client-info {
  margin-bottom: 0;
}
.team-dtl .client-info p {
  font-size: 14px;
  margin-top: 3px;
}
.team-dtl .client-info p.price {
  font-weight: 600;
  color: #2e1983;
  font-size: 20px;
  margin-top: 2px;
}
.user-team-img {
  height: 88px;
  width: 88px;
  border-radius: 50%;
  overflow: hidden;
}
.user-team-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}
.team-dtl .client-info h5 {
  font-size: 20px;
}
.user-event-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.user-event-header h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: #2e1885;
}
.fees {
  font-size: 14px;
  color: #121212;
  margin-bottom: 0;
}
.fees span {
  font-weight: bold;
}
.event-card-body {
  background: #f1f3f6;
  border-radius: 8px;
  padding: 16px 20px;
}
.team-user-slider .owl-nav img {
  max-width: 12px;
  filter: brightness(10000000000000);
}
.team-user-slider .owl-nav .owl-next {
  position: absolute;
  right: -15px;
  top: 0;
  background: #2e1885;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.team-user-slider .owl-nav .owl-prev {
  background: #2e1885;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -15px !important;
  position: absolute;
  border-radius: 2px;
}
/* .ql-container.ql-snow {
  border: none;
} */
.team-user-slider .owl-nav .disabled {
  opacity: 0.5;
}
.event-card-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  color: #121212;
  font-weight: 600;
}
.event-dtl {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 10px;
}
.event-dtl li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.event-dtl li h5,
.event-dtl li h6 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #121212;
}
.event-dtl li:last-child {
  margin-bottom: 0;
}
.assigned-event-sec h4 {
  font-weight: 700;
  font-size: 18px;
  color: #22282e;
  margin-bottom: 12px;
}
.assigned-event-sec .client-dtl.event-cls {
  margin-bottom: 24px;
}
.team-user-slider .owl-nav {
  position: absolute;
  top: calc(50% - 13px);
  width: 100%;
}
.team-user-slider .owl-nav button {
  height: 26px;
  width: 26px;
  background: #2e1983 !important;
  border-radius: 50%;
  position: absolute;
  left: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1 !important;
}
.team-user-slider .owl-nav button.owl-next {
  right: -14px;
  position: absolute;
  left: auto;
}
.team-user-slider .owl-nav button span {
  font-size: 22px;
  line-height: 15px;
  color: #fff;
  font-weight: 500;
  font-family: cursive;
  margin-top: -3px;
}
.team-user-slider .owl-nav button.disabled {
  opacity: 0.5;
}
.inner-title.setting-navs {
  width: 100%;
  display: block;
}
.inner-title.setting-navs .nav {
  background: transparent;
  box-shadow: none;
  position: relative;
}
.inner-title.setting-navs nav {
  position: relative;
}
.inner-title.setting-navs nav:after,
.inner-title.setting-navs .nav .nav-link:after {
  content: "";
  position: absolute;
  height: 4px;
  background: #d8dbe6;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 44px;
}
.inner-title.setting-navs .nav .nav-link {
  position: relative;
}
.inner-title.setting-navs .nav .nav-link.active:after {
  background: #2e1885;
}
.inner-title.setting-navs .nav .nav-link.active {
  background: transparent !important;
  color: #2e1983 !important;
  box-shadow: none !important;
  /* border-bottom: solid 4px #2e1983 !important; */
  position: relative;
  z-index: 11;
  border-radius: 0 !important;
}
.date-cls + .event-dtl {
  margin-top: 0;
}
.input_group {
  display: flex;
  margin: 10px 0 16px;
}

.input_group input.form-control {
  width: 100px;
  margin-right: 10px;
}

.input_group select.form-control {
  width: 300px;
  appearance: auto;
}

.client-info.setting-inner .form-group {
  margin-bottom: 0;
}
.client-info.setting-inner h5 {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.client-info.setting-inner p {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.project-category-wrap {
  margin-top: 10px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
}
.project-category-wrap ul {
  padding-left: 0;
  list-style: none;
}
.project-category-wrap ul li {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: solid 1px #dee2e6;
  margin-top: 0;
  color: #343a40;
}
.project-category-wrap ul li:first-child {
  padding-top: 0;
}
.project-category-wrap ul li h5 {
  margin-bottom: 0;
}
.add-category {
  text-align: center;
  margin-top: 15px;
}
.add-category .btn_primary {
  background: #e9eaff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #22282e;
}

.client-info.setting-inner + .client-info.setting-inner {
  margin-bottom: 0;
}
.form-check label.form-check-label {
  margin-bottom: 0;
  font-weight: 500;
}
.booking-dtl-cls .booking-card .tag {
  margin-top: 0;
}

.booking-dtl-cls .booking-card .notifi-dot span {
  display: flex;
  align-items: center;
}
.event-tag {
  display: flex;
  margin-top: 12px;
}
.event-tag span {
  border: 1px solid #d2d2e8;
  border-radius: 24px;
  padding: 4px 15px;
  margin-right: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #22282e;
}
.pro-tit h5.price {
  font-size: 18px;
}
.enable-packages {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 6px 20px;
  border-radius: 5px;
  gap: 10px;
}
.form-check.form-switch {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: unset !important;
  min-height: unset;
}
.form-switch .form-check-input:checked {
  background-color: #2e1a81;
  border-color: #2e1a81;
}
.form-switch .form-check-input {
  height: 24px;
  width: 55px;
  outline: 0 !important;
  box-shadow: none !important;
  border: solid 2px #d2d2e8;
  background-color: #d2d2e8;
}
.enable-packages {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 6px 20px;
  border-radius: 5px;
  gap: 10px;
}
.form-check.form-switch {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: unset !important;
  min-height: unset;
}
.form-switch .form-check-input:checked {
  background-color: #2e1a81;
  border-color: #2e1a81;
}
.form-switch .form-check-input {
  height: 24px;
  width: 55px;
  outline: 0 !important;
  box-shadow: none !important;
  border: solid 2px #d2d2e8;
  background-color: #d2d2e8;
}
.pro-tit h5 {
  font-size: 14px;
}
.rating-star {
  line-height: 1;
}
.rating-star img {
  max-width: 15px;
}
.pro-card.card {
  padding: 12px;
}
.pro-address p {
  font-size: 12px;
}
.date-wrap p {
  font-size: 12px;
}
.session-wrap p {
  font-size: 12px;
}
.client-info.setting-inner li h5 {
  font-size: 16px;
  font-weight: 400;
}
.notification-wrap h4 {
  font-weight: 700;
  font-size: 18px;
  color: #22282e;
  margin-bottom: 12px;
}
.message-label + textarea.form-control {
  height: unset;
  min-height: 200px;
}

.booking-link h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

.booking-link p {
  margin-bottom: 0;
  font-size: 15px;
}

.autoresponder-wrap {
  margin-top: 24px;
}

.autoresponder-title h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

.autoresponder-wrap p {
  margin-bottom: 10px;
}
.message-label a {
  color: #2e1983;
  font-weight: 500;
}
.login-tour-img img {
  width: auto !important;
  max-width: 350px;
  height: auto;
  object-fit: contain;
}
.login-cont {
  padding: 60px 100px;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
}
.login-tour-img {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 250px;
}

.login-form {
  /* height: 100vh; */
  background: #2e1983;
  padding: 75px 140px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  margin: 0 auto;
  text-align: center;
  overflow: auto;
  height: 100%;
}
.login-sec.customer-form-main .row.m-0 {
  align-items: center;
}
.login-form-cont h6 {
  color: #fff;
  max-width: 220px;
  margin: 30px auto 50px;
  font-size: 18px;
  line-height: 1.5;
}
.login-form label {
  text-align: left;
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
}
.login-btn .btn_primary {
  background: #fc5c59;
  border-radius: 50px;
  width: 100%;
}
.owl-dots {
  display: flex;
  justify-content: center;
  gap: 7px;
  margin-top: 15px;
}
.login-form .form-control {
  border-radius: 50px;
  background: transparent !important;
  height: 43px;
}
.sign-up-free .light_btn {
  border-radius: 50px;
  background: #432d9d;
  padding: 10px 30px;
  color: #fff;
  margin-bottom: 0;
}
.sign-up-free {
  margin-top: 30px;
}
.form-group.pw-show-cls input.form-control {
  padding-right: 50px;
}
.login-inner-cont {
  max-width: 380px;
  margin: 15px auto 0;
  color: 3;
}
.customer-form-logo h6 {
  color: #fff;
  font-size: 20px;
  max-width: 70%;
  margin: 25px auto 0;
}
.login-inner-cont h3 {
  color: #2e1a81;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}
.login-inner-cont p {
  color: #121212;
  font-size: 18px;
  margin-bottom: 0;
}
.login-carousel .owl-dot span {
  height: 10px !important;
  width: 10px !important;
  border: solid 2px #2e1a81 !important;
  display: block !important;
  background: #2e1a81 !important;
  margin: 0 !important;
  border-radius: 35px;
}
.login-carousel .owl-dots.disabled {
  display: flex !important;
  justify-content: center;
  margin-top: 20px;
}
.login-carousel .owl-dot.active span {
  background: #fff !important;
}
.login-form .form-control::placeholder {
  color: #7b6cba;
  opacity: 1; /* Firefox */
}
.login-form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7b6cba;
}
.login-form .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7b6cba;
}

.form-group {
  position: relative;
}
.login-form .form-control {
  color: #fff;
  border-color: #7b6bb5;
  width: 100%;
}
.login-form.signup-form .selectPackage + .login-btn {
  margin-top: 30px;
}
.pw-show {
  position: absolute;
  top: calc(100% - 35px);
  right: 15px;
}
.pw-show img {
  max-width: 20px;
  cursor: pointer;
}
.uplaod-logo .file_input span {
  border: 1px solid #7b6bb5;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  height: 100%;
  color: #7b6bb5;
  text-align: left;
  display: flex;
  border-radius: 35px;
  align-items: center;
  gap: 10px;
}
.uplaod-logo .file_input span img {
  filter: invert(1);
}
.sign-up-free .light_btn a {
  color: #fff;
  font-weight: bold;
}
.login-cont.customer-form {
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}
.login-sec.customer-form-main {
  background: #2e1a81;
  min-height: 100vh;
}
.customer-form-main .login-form {
  padding: 25px 25px;
}

.login-cont.customer-form h3 {
  font-size: 58px;
  color: #fff;
  font-style: italic;
}

.login-cont.customer-form .login-inner-cont {
  max-width: 440px;
}
.login-cont.customer-form .login-tour-img {
  height: unset;
}

.login-cont.customer-form .login-tour-img img {
  max-width: 100%;
  object-fit: cover !important;
}
.customer-form-bg {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}

.customer-form-bg h6 {
  max-width: 80%;
  margin: 10px auto 15px;
  color: #303030;
}

.customer-form-bg label {
  color: #303030;
}

.customer-form-bg .form-control::placeholder {
  color: #828c97;
  opacity: 1; /* Firefox */
}
.customer-form-bg .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #828c97;
}
.customer-form-bg .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #828c97;
}
.customer-form-bg .form-control {
  color: #828c97;
  border-color: #828c97 !important;
}

.login-form label.otp-input + div {
  gap: 20px !important;
}

.login-form label.otp-input + div input[type="text"] {
  border-radius: 50px;
  width: 100% !important;
  background: transparent !important;
  border: solid 1px #7b6bb5;
  color: #fff;
  font-size: 16px !important;
  height: 46px !important;
  padding: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.error-msg {
  margin-bottom: 0;
  color: #f00;
  font-size: 10px;
  position: absolute;
  bottom: -20px;
  left: 0px;
  line-height: 1.0;
  text-align: left;
}
.createform-tit {
  display: flex;
  align-items: center;
}
.createform-tit h3 {
  margin-bottom: 0;
  font-size: 18px;
}
.add-form a {
  display: flex;
}
.add-form {
  margin-left: auto;
}
.create-form {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
}
form.create-form-main {
  margin-top: 15px;
}

label.form-label {
  margin-bottom: 3px !important;
}
.custom-radio {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  margin-bottom: 10px;
  line-height: 1.3;
  font-weight: 500;
  font-size: 14px;
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}
.custom-radio input:checked ~ .checkmark {
  background-color: #2e1983;
}
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}
.custom-radio .checkmark:after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  inset: 0;
  margin: auto;
}
.list-form {
  margin-top: 15px;
}
.inquery-card-form.card {
  padding: 15px;
  margin-bottom: 15px;
}
select.form-select {
  min-height: 43px;
  border-color: #d2d2e7;
  outline: 0 !important;
  box-shadow: none !important;
}
.inquery-card-form label {
  justify-content: space-between;
  margin-bottom: 10px !important;
}
.inquery-card-form.card .form-group .form-control,
.inquery-card-form.card .form-group select.form-select {
  max-width: 90%;
  margin: 0 auto;
}
.add-btn {
  text-align: right;
}
.form-group.select-opt {
  margin-bottom: 10px;
}
.inquery-card-form.card .form-group {
  margin-bottom: 0;
}
.booking-form-inner {
  padding: 60px 0;
}
.booking-form-inner-cont {
  margin-top: 30px;
}
.booking-form-inner-cont h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 12px;
}
.booking-form-inner-cont p {
  font-size: 18px;
  color: #b9b9e8;
  margin-bottom: 0;
}
.booking-form-card {
  margin-top: 25px;
  padding: 40px;
  background: #fff;
  border-radius: 16px;
}
.form-step-wrap h4 {
  color: #fe5f55;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
.form-step-wrap {
  margin-bottom: 15px;
}
.form-title h5 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-title p {
  font-weight: 400;
  font-size: 16px;
  color: #828c97;
}
.step-from-btn .btn_border {
  width: 100%;
  min-height: 42px;
  border-radius: 7px;
  background: #fff;
  border-color: #2e1b7f;
  color: #2e1b7f !important;
}

.btn_secondary {
  background: #fc5c59;
  min-height: 43px;
  width: 100%;
  border-color: #fc5c59;
  color: #f7f7ff;
  font-size: 16px;
}
.btn_secondary:hover {
  background: #cd4845;
}
.plan-inner {
  background: #ffffff;
  border-top: 2px solid #2e1885 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #fff;
}
.plan-wrap .item {
  padding: 5px;
}
.plan-inner h5 {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #2e1885;
  margin-bottom: 0;
}
.plan-inner ul li {
  font-size: 16px;
  line-height: 1.2;
  color: #464646;
  margin-bottom: 12px;
}
.plan-inner ul {
  padding-left: 30px;
  margin-bottom: 35px;
  margin-top: 35px;
}
.plan-inner ul li:last-child {
  margin-bottom: 0;
}
.plan-wrap {
  margin-bottom: 20px;
}
.plan-inner h5.plan-price {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 26px;
}
.additional-option h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.additional-option-list {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 6px 16px;
  margin-top: 8px;
}
.single-option {
  display: flex;
  padding: 10px 0;
  border-bottom: solid 1px #f1f1f1;
  justify-content: space-between;
}

.single-option label {
  margin-bottom: 0 !important;
  color: #343a40;
  font-weight: 400;
}

.single-option p {
  margin-bottom: 0;
}

.single-option:last-child {
  border-bottom: 0;
}

.additional-option {
  margin-top: 30px;
}

.additional-opt-price p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.add-hours-sec {
  margin-top: 25px;
}
.add-price-opt {
  display: flex;
  margin-top: 15px;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.price-box-left {
  display: flex;
  gap: 8px;
}
.add-hours-sec h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.add-hours-sec p {
  margin-bottom: 0;
}
.price-box-left button {
  color: #2e1b7f !important;
  font-size: 24px;
  background: #ffffff !important;
  border: 0 !important;
  width: 44px;
  font-weight: bold;
  line-height: 1;
  height: 44px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.input-form {
  width: 70px;
}
.input-form .form-control {
  padding: 0;
  border: 0;
  height: 100%;
  text-align: center;
}

.price-right {
  margin-left: auto;
}

.price-right p {
  font-size: 24px;
}
.next-prev-btn {
  margin-top: 25px;
}
.add-session {
  margin-bottom: 25px;
}
.add-session a {
  color: #2e1b7f;
  font-weight: 600;
}
.session-card.card {
  padding: 15px;
}
.edit-opt a {
  font-weight: 600;
  color: #2e1983;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.session-list {
  margin-bottom: 20px;
}
.edit-opt {
  padding: 10px 0 0;
  display: flex;
  justify-content: space-between;
}
.edit-opt a span {
  margin-left: 10px;
}
.thank-you {
  margin: 0 auto;
  max-width: 290px;
  text-align: center;
}
.thankyou-img {
  margin-bottom: 20px;
}
.thankyou-cont h3 {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-bottom: 15px;
}
.thankyou-cont p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
}
.form-title h5 a {
  color: #2e1b7f;
}
.form-group.uplaod-item span {
  border: dashed 4px #d3d3d3;
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  transition: ease-in-out 0.3s;
}
.file_input:hover span {
  border-color: #9c85ff;
}
.form-group.uplaod-item span span {
  border: 0;
  width: auto;
  background: #ebebeb;
  display: inline-flex;
  padding: 8px 20px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #2e1885;
  transition: ease-in-out 0.3s;
}

.file_input:hover span span {
  background: #9c85ff38;
  color: #2e1885 !important;
}
.form-control:focus {
  background-color: #f7f7ff !important;
  border: 1px solid #fe5f55;
  transition: all 0.5s;
}
.form-group.pw-show-cls + .form-group {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
}
.form-group.pw-show-cls + .form-group label {
  margin-bottom: 0;
}
.customer-user-dtl {
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px rgb(0 0 0 / 16%);
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.customer-user-dtl .customer-img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  margin-right: 15px;
}
.photographer-name h4 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}
.customer-user-dtl + .form-step-wrap {
  margin-top: 20px;
}
.booking-form-inner-cont + .customer-user-dtl {
  background: #ffff;
  margin-top: 20px;
}
.plan-inner.active {
  border: solid 2px;
}
.form-control:focus {
  background-color: #fff !important;
  border: 1px solid #fe5f55;
  transition: all 0.5s;
  color: #7767b4;
}
.react-tel-input.form-control input:-webkit-autofill,
.react-tel-input.form-control input:-webkit-autofill:hover, 
.react-tel-input.form-control input:-webkit-autofill:focus, 
.react-tel-input.form-control input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}
.customModal .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.pageMainWraper {
  padding: 60px 0;
  background: #f2f1f4;
}
.pageHeader .photoGrapherOuter .proImgOuter {
  width: 64px;
  height: 64px;
}
.pageHeader .photoGrapherOuter .proImgOuter img {
  object-fit: cover;
  object-position: top center;
}
.pageHeader .photoGrapherOuter .photographerName {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.5;
  color: #22282e;
  margin: 20px 0;
}
.btnVisitBtn {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #6610f2;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 24px;
  border-radius: 6px;
  background-color: #ebe5fc;
  border-color: #ebe5fc;
  transition: ease-in-out 0.3s;
}
.btnVisitBtn:hover {
  background-color: #6610f2;
  border-color: #6610f2;
  color: #fff;
}
.px-12px {
  padding: 0 12px !important;
}
.formTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #22282e;
  text-align: center;
}
.formMainOuter {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 55px;
}
.formMainOuter .formInner {
  margin-top: 44px;
}
.formMainOuter .login-form {
  background-color: transparent;
}
.formMainOuter .form-group {
  margin-bottom: 32px;
}

.formMainOuter .form-group + p {
  font-size: 12px;
}
.formMainOuter label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  margin-bottom: 10px !important;
}
.formMainOuter .form-control {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #dee2e6, #dee2e6) !important;
  border: 1px solid #dee2e6;
  padding: 9px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
}
.formMainOuter .form-control::placeholder {
  color: #adb5bd;
}
.btnSubmit {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 15px 24px;
  border-radius: 8px;
  background: #2e1885;
  border-color: #2e1885 !important;
  color: #fff !important;
  transition: ease-in-out 0.3s;
}
.btnSubmit:hover {
  background: #3c2499;
  border-color: #3c2499 !important;
}

.backFillBtn {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 15px 24px;
  border-radius: 8px;
  color: #6610f2;
  background-color: #ebe5fc;
  border-color: #ebe5fc;
  transition: ease-in-out 0.3s;
}
.backFillBtn:hover {
  background-color: #6610f2;
  border-color: #6610f2;
  color: #fff;
}

.pageFooter {
  margin-top: 100px;
}
.pageFooter .footerOuter .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
  color: #000;
}
.pageFooter .footerOuter .companyLogo {
  width: 120px;
}
.stepsOuter .formSubTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #22282e;
  margin-bottom: 12px;
}
.stepsOuter .stepsMain {
  width: 100%;
  height: 4px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #ceccd1;
  margin-bottom: 44px;
}
.stepsOuter .stepsMain::before {
  content: "";
  display: block;
  height: 100%;
  width: 20%;
  border-radius: 50px;
  background-color: #2e1885;
}
.stepsOuter.step_2 .stepsMain::before {
  width: 40%;
}
.stepsOuter.step_3 .stepsMain::before {
  width: 60%;
}
.stepsOuter.step_4 .stepsMain::before {
  width: 80%;
}
.stepsOuter.step_5 .stepsMain::before {
  width: 100%;
}

.stepsOuter .custom-checkbox input:checked ~ .checkmark {
  background-color: #7749f8;
  border-color: #7749f8;
}
.stepsOuter .custom-checkbox {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0 !important;
}
.selectPackage .packageItem {
  box-shadow: 0px 4px 12px 0px #0000001a;
  border: 2px solid transparent;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  /* max-height: 200px;
  height: 100%; */
}
.selectPackage .packageItem.subscriptionItem {
  max-height: 170px;
  height: 100%;
}

.selectPackage .packageItem.subscriptionItem .customRadio {
  width: 18px;
  height: 18px;
}
.selectPackage .packageItem.subscriptionItem .content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.selectPackage .packageItem.subscriptionItem .validity {
  font-size: 13px;
  color: #2c3542;
}
.selectPackage .packageItem.selected {
  border-color: #2e1885;
}
.selectPackage .packageItem.selected2 {
  border-color: #fc5c59;
}
.selectPackage .packageItem:last-child {
  margin-bottom: 0 !important;
}
.selectPackage .packageItem .packageHeader {
  margin-bottom: 12px;
}
.selectPackage .packageItem .packageHeader .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2px;
  color: #2e1885;
}
.selectPackage .packageItem .packageHeader .price {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #2e1885;
}
.selectPackage .packageItem .content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
}
.selectPackage .packageItem .btnSeeMore {
  text-decoration: none;
  color: #2e1885;
}
.btnAddSession {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 10px 24px;
  border-radius: 8px;
  color: #2e1885 !important;
  background-color: transparent;
  border-color: #2e1885;
  transition: ease-in-out 0.3s;
}
.btnAddSession:hover {
  background-color: #2e1885;
  color: #fff !important;
}

.txt {
  max-width: 440px;
}
.totalPrice {
  font-size: 24px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
  white-space: nowrap;
}
.stepsOuter .step-from-btn {
  height: 100%;
}
.formMainOuter .incDecInp::-webkit-outer-spin-button,
.formMainOuter .incDecInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.session-list {
  border-color: #d2d2e8 !important;
}
.session-list .session-card .eventTitle {
  font-weight: bold;
  letter-spacing: 1px;
}
.session-list .session-card .eventDate {
  font-weight: bold;
  font-size: 16px;
}
.session-list .session-card .address,
.session-list .session-card .eventTime {
  font-size: 15px !important;
  font-weight: 500;
}
.additionalInfoTabs {
  border-radius: 12px;
}
.additionalInfoTabs .nav-item {
  width: calc(100% / 2);
}
.additionalInfoTabs .nav-item .nav-link {
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding: 12px;
  transition: ease-in-out 0.3s;
}
.additionalInfoTabs .nav-item .nav-link:hover {
  color: #2e1885;
  background-color: #2e18851f;
}
.additionalInfoTabs .nav-item .nav-link.active {
  color: #fff;
  background-color: #2e1885;
}
.uploadeFileOuter {
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px #0000001a;
}
.uploadeFileOuter .uploadFileHeader .fileTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #2e1885;
}
.uploadeFileOuter .fileName {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
}
.additionalNote {
  border-color: #d2d2e8 !important;
  padding-top: 32px;
  margin-top: 32px;
}
.additionalNote .form-control {
  min-height: 60px;
}

.bookingDetailMain .bookingDetailInner {
  max-width: 600px;
  border-collapse: collapse;
  padding: 0;
  margin: 20px auto;
  font-size: 17px;
}
.formMainOuter.contractOuter {
  max-width: 800px;
}
.formMainOuter.contractOuter .bookingDetailMain .bookingDetailInner {
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}
.bookingDeatilHeader {
  padding: 15px 30px 10px;
  background-color: #2e1885;
}
.bookingDeatilHeader .pdfTitle {
  font-size: 20px;
  font-weight: 500;
}
.bookingDeatilHeader .pera {
  font-size: 16px;
}
.bookingDetailBody .photographerProfile .photoGrapherImg {
  width: 70px;
  height: 70px;
}
.bookingDetailBody .photographerProfile .photoGrapherImg img {
  object-fit: cover;
  object-position: top center;
}
.bookingDetailBody .photographerProfile .photoGrapherName {
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
  color: #2e1885;
}
.bookingDetailBody .details .sectionTitle {
  font-size: 16px;
  font-weight: 700;
  color: #22282e;
}
.bookingDetailBody .details .clientName,
.bookingDetailBody .details .address,
.bookingDetailBody .details .clientContact .contact,
.bookingDetailBody .details .contractDec .contractPera {
  font-size: 16px;
  font-weight: normal;
  color: #22282e;
  line-height: 22px;
}
.serviceDetail .packageOuter .packageTitle,
.serviceFees .packageOuter .packageTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #22282e;
}
.serviceFees .packageOuter .feesTitle {
  font-size: 14px;
  font-weight: 600;
  color: #22282e;
}
.serviceFees .packageOuter .price {
  font-size: 14px;
  font-weight: 500;
  color: #22282e;
}
.serviceDetail .packageOuter .date,
.serviceDetail .packageOuter .time,
.serviceDetail .packageOuter .add {
  font-size: 14px;
  font-weight: 500;
  color: #22282e;
}
.bookingDetailBody .details .detailsTable {
  border: 1px solid #ededed;
  width: 100%;
  border-spacing: 0;
  border-bottom: none;
}
.bookingDetailBody .details .detailsTable tbody tr th {
  width: 50%;
  padding: 8px 24px;
  text-align: left;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-weight: 500;
  font-size: 15px;
}
.bookingDetailBody .details .detailsTable tbody tr td {
  width: 50%;
  padding: 8px 24px;
  text-align: left;
  border-bottom: 1px solid #ededed;
  font-size: 15px;
}
.bookingDetailBody .eventsDetail {
  border: 1px solid #2e18852e;
  border-radius: 12px;
}
.bookingDetailBody .eventsDetail .eventsTitle {
  font-size: 16px;
  font-weight: 600;
  color: #2e1885;
  margin-bottom: 12px;
}
.bookingDetailBody .eventsDetail .eventsOuter {
  gap: 12px;
}
.bookingDetailBody .eventsDetail .eventsOuter .eventItem {
  padding: 16px;
  border-radius: 12px;
  background-color: #ebebeb;
}
.bookingDetailBody
  .eventsDetail
  .eventsOuter
  .eventItem
  .eventHeader
  .eventTitle {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2e1885;
}
.bookingDetailBody
  .eventsDetail
  .eventsOuter
  .eventItem
  .eventHeader
  .eventDate {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.bookingDetailBody .eventsDetail .eventsOuter .eventItem .eventAdd,
.bookingDetailBody .eventsDetail .eventsOuter .eventItem .eventTime {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}
.bookingDetailBody .eventsDetail .eventsOuter .eventItem .eventTeam {
  font-size: 14px;
  font-weight: 500;
}
.bookingDetailBody .packageDetail {
  background-color: #2e18852e;
  border-radius: 12px;
}
.bookingDetailBody .packageDetail .packageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.bookingDetailBody .packageDetail .packageHeader .packageTitle {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2e1885;
}
.bookingDetailBody .packageDetail .packageHeader .packageTiming {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.bookingDetailBody .packageDetail .pakcagePrice {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-bottom: 4px;
}
.bookingDetailBody .packageDetail .packageDec {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.bookingDetailFooter .form-check .form-check-input {
  width: 18px;
  height: 18px;
  cursor: pointer;
  flex: 0 0 auto;
}
.contractOuter .bookingDetailFooter .form-check .form-check-input {
  width: 20px;
  height: 20px;
}
.bookingDetailFooter .form-check .form-check-input:checked {
  background-color: #2e1885;
  border-color: #2e1885;
}
.bookingDetailFooter .form-check .form-check-input:focus {
  border-color: #2e1885;
}
.bookingDetailFooter .form-check .form-check-label {
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #2c3542;
}
.contractOuter .bookingDetailFooter .form-check .form-check-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.bookingDetailFooter .form-check .form-check-label a {
  color: #2e1885;
}
.card.inboxCustomCard {
  max-height: 400px;
  overflow: hidden auto;
}
.dashboard-card a {
  text-decoration: none;
}
.notificationMainOuter .notificationInner .notificationItem.unread {
  border-color: #2e1885 !important;
  background-color: #cccccc21;
}
.notificationItem {
  padding: 10px;
  border-color: #cccccc47 !important;
}
.notificationItem .imgOuter {
  width: 50px;
  height: 50px;
  border: 4px solid;
  border-color: #cccccc47;
}
.notificationItem .btn-close,
.notificationModal .modal-header .btn-close {
  font-size: 12px;
}
.notificationItem .notificationTime {
  font-size: 12px;
  white-space: nowrap;
  color: #9f9f9f;
}
.notificationItem .title {
  font-size: 15px;
  color: #22282e;
  font-weight: 700;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.notificationItem .pera {
  font-size: 14px;
  width: 100%;
  color: #727272;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.notificationModal .modal-header .modal-title {
  font-size: 18px;
  font-weight: 700;
}

.notificationModal .notificationItem .notificationTime {
  font-size: 13px;
}
.notificationModal .notificationItem .title {
  font-size: 16px;
}
.notificationModal .notificationItem .pera {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  -webkit-line-clamp: unset;
}

.projectDetail .eventName {
  border: 1px solid #d2d2e8;
  font-size: 12px;
  font-weight: 400;
  color: #22282e;
}
.projectDetail .client-dtl .card {
  padding: 16px 20px;
}
.projectDetail .client-dtl .card .session-header h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}
.projectDetail .client-dtl .card .session-header .dots {
  width: 42px;
  height: 42px;
}

.projectDetail .location-wrap p {
  font-weight: 500;
  font-size: 14px;
  color: #0085ff;
}

.projectDetail .card {
  box-shadow: 0px 4px 12px 0px #0000001a;
}
.projectDetail .client-dtl h4 {
  font-size: 16px;
  color: #000;
}
.projectDetail .client-info h5 {
  font-weight: 500;
  color: #000;
}
.signle-client-info ul li a {
  width: 20px;
  height: 20px;
  display: flex;
}
.signle-client-info:first-child {
  margin: 0 !important;
}
.file_wrap p {
  font-size: 12px;
  font-weight: 400;
  color: #696969;
  line-height: 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.panel-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.sidebar-menu {
  overflow: hidden auto;
  height: calc(100vh - 390px);
}
.feedback-cls {
  padding: 0 24px 24px;
  text-align: center;
}

.feedback-cont {
  background: #eef0ff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.booking-card-main-cls .booking-card {
  width: 100%;
  margin-bottom: 0;
}

.booking-card-main-cls button {
  padding: 0 !important;
}

.booking-card-main-cls {
  align-items: center;
  margin-bottom: 15px;
}

.booking-card-main-cls .dropdown-item {
  background: transparent !important;
  color: #000;
}
.AddnewTeam {
  flex: 0 0 70%;
  max-width: 70%;
}
.addNewBtn {
  flex: 0 0 30%;
  max-width: 30%;
  margin-top: 26px;
}

.addNewBtn .btn_primary {
  background: #f1f1f1;
  color: #000;
  border-color: #f1f1f1 !important;
}

.formMainOuter .formInner .login-form .form-group .react-tel-input.form-control {
  width: 100%;
  padding: 0;
}
.formMainOuter .quill.form-control {
  height: unset !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: solid 1px #d1d1d1;
}
.customModal .modal-content {
  border-radius: 36px;
}
.bookingDrop .dropdown-toggle {
  background-image: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 8px;
}
.bookingDrop .dropdown-toggle:after {
  display: none;
}

.remember {
  cursor: pointer;
}
.notifi-dot button:active {
  background-color: transparent !important;
}

.customCheck {
  width: 18px;
  height: 18px;
}
.customCheck:checked {
  background: #2c1782 !important;
}

.optionsOuter {
  border: 1px solid #d2d2e8;
  border-bottom: none;
  border-top: none;
  padding: 0 16px 0 16px !important;
}
.optionsOuter:first-child {
  border-radius: 8px 8px 0 0;
  border-top: 1px solid #d2d2e8 !important;
  padding: 16px 16px 0 16px !important;
}
.optionsOuter:last-child {
  border-radius: 8px 8px;
  border-bottom: 1px solid #d2d2e8 !important;
  padding: 16px 16px 16px 16px !important;
  margin-bottom: 16px;
}
.optionsOuter + .optionsOuter:last-child {
  border-radius: 0 0 8px 8px;
  padding: 0px 16px 16px 16px !important;
}
.optionsOuter:last-child .form-group {
  margin-bottom: 0 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.btn_default {
  background: #e9eaff !important;
  border-radius: 28px;
  color: #000;
  border-color: #e9eaff !important;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
}
.contract_btn {
  display: flex;
  gap: 10px;
}
.projectDetail.project-contract-inner {
  max-width: 1000px;
}
.projectDetail.project-contract-inner .bookingDetailBody {
  border-radius: 8px;
}
.contractDec p:last-child {
  margin-bottom: 0;
}
.clent-inner-dtl {
  padding: 20px 0;
  border-bottom: solid 1px #d5daec;
  border-top: solid 1px #d5daec;
  margin-top: 30px;
}
.name-wrap {
  margin-bottom: 15px;
}
.email-wrap {
  margin-bottom: 15px;
}
.phone-wrap {
  margin-bottom: 15px;
}
.clent-inner-dtl h4 {
  margin-bottom: 15px;
}
.email-wrap a {
  color: #0085ff;
  text-decoration: none;
}

.requested-service {
  padding: 15px 0;
  border-bottom: solid 1px #d5daec;
  padding-bottom: 20px;
}
.requested-service h4 {
  margin-bottom: 15px;
}
.price-box {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px #0000001a;
  padding: 20px;
}
.price-box ul {
  padding-left: 0;
}
.price-box ul li {
  font-size: 16px;
  font-weight: 600;
  color: #22282e;
}
.price-box ul li span.price-font {
  color: #2e1885;
}
.total-price {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #c7c7c7;
  padding-top: 10px;
  margin-top: 10px !important;
}
.attachment-wrap h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-dtl.attachment-wrap {
  border-bottom: solid 1px #d5daeb;
  padding-bottom: 20px;
}
.client-dtl.additional-note .file_wrap {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.additional-note a {
  text-decoration: none;
  font-size: 16px;
  color: #2fa4fc;
}
.contract-box {
  margin-top: 20px;
  border: solid 1px #e5deff;
  padding: 20px;
  border-radius: 7px;
}

.contract-box h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.contract-box a {
  text-decoration: none;
}

.contract-box a p.fst-italic {
  margin-top: 5px !important;
}
.breadcurm-title {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 15px;
}
.breadcurm-title .eventName {
  margin-bottom: 0 !important;
}

.plus .btn-primary {
  background: #2e1885;
  border-color: #2e1885 !important;
  height: 40px;
  width: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 10px;
}

.minus .btn {
  border-color: #2e1885!important;
  background: #2e1885;
  height: 40px;
  width: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 10px;
}

.input-form input.form-control {
  border-radius: 0;
  padding: 10px !important;
}
.add-hours-main {
    display: flex;
    border: solid 1px #2e1885;
    border-radius: 50px;
    overflow: hidden;
    
}
.add-hours-main .btn {
  border-radius: 50%;
}
.add-hours-main +  h4 {
  margin-left: auto !important;
}
.total-price span {
  font-weight: bold;
}
.price-bold {
  font-weight: bold;
}

.form-group.uplaod-logo .file_input span img {
  max-width: 45px;
  height: 45px;
  object-fit: cover;
}

.form-group.uplaod-logo .file_input span {
  padding: 0 !important;
  height: 43px;
  overflow: hidden;
}
.mt-50{margin-top: 50px !important;}
.mt-10{margin-top: 10px !important;}
.ml-12{margin-left: 12px !important;}
.mr-absolute-0{position: absolute;right: 0;}
.not-found-img {
  text-align: center;
  padding: 50px 0;
}
.selectPackage {
  position: relative;
  /*bottom: 30px;*/
}
.selectPackage .packageItem.subscriptionItem:has(+ .error-msg) {
  margin-bottom: 0 !important;
}
.bookingDrop .dropdown-menu {
  padding: 10px 15px;
  min-width: 120px;
}
.form-check-input:checked {
  background-color: #2e1885 !important;
  border-color: #2e1885 !important;
}
.form-group.uplaod-logo.upload-setting-logo .user-img {
  position: absolute;
  top: 4px;
  margin-bottom: 0;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  overflow: hidden;
  left: 4px;
}
.form-group.uplaod-logo.upload-setting-logo span {
  border-radius: 8px;
  border-color: #d2d2e8;
  padding-left: 50px !important;
}
.form-group.uplaod-logo.upload-setting-logo .user-img img{
  width: 100%;
  height: 100%;
}
.contract-box1 .file_wrap .btn.btn_default {
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
}

.contract-box1 .file_wrap {
  margin-bottom: 25px !important;
  border-bottom: solid 1px #DEE2E6;
  padding-bottom: 25px;
}
.react-tel-input.form-control {
  padding: 3px 15px;
  padding-left: 0;
}
.react-tel-input.form-control input {
  border: 0 !important;
  padding-left: 50px;
  outline: 0 !important;
}
.react-tel-input.form-control .flag-dropdown {
  border: 0 !important;
  background: transparent !important;
  border-right: solid 1px #f1f1f1 !important;
}
.login-form .react-tel-input .flag-dropdown {
  background: transparent !important;
  border: 0 !important;
  border-right: solid 1px #7b6bb5 !important;
  border-radius: 40px 0 0 40px;
}

.login-form .react-tel-input .flag-dropdown:hover {
  background: transparent !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background: transparent;
}
.pro-card.card.team-card {
  display: flex;
  justify-content: start;
  flex-direction: unset;
}
.pro-card.card.team-card .user-dot {
  flex: 0 0 15px;
  max-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding-left: 5px;
}
.pro-card.card.team-card .user-dot .notifi-dot {
  padding: 0;
}
.resend-link {
  margin-left: auto;
  color: #fc5c59;
  background: transparent !important;
  border: 0;
  box-shadow: none;
}
.resend-cls span {
  color: #fff;
}
.resend-cls {
  margin-bottom: 10px;
}
.client-info.setting-inner.s-cls .input_group input {
  width: 100%;
}
.message-label select {
  border: 0 !important;
  appearance: none !important;
  width: auto !important;
  margin-bottom: 2px;
  text-align: center;
  text-decoration: underline !important;
  outline: 0 !important;
  box-shadow: none;
}
.event-due-date .mark-complate.mt-2 {
  margin-top: 0 !important;
  display: flex;
  width: 100%;
}

.event-due-date .mark-complate.mt-2 p {
  margin-bottom: 0;
  margin-left: 15px;
}

.event-due-date .eventName {
  padding: 5px 15px !important;
  flex: 0 0 auto;
  align-items: center;
}

.breadcrum-left.breadcrum-cust {
  flex: 0 0 auto;
  width: 100%;
}

.plan-opt {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: center;
}
.select-subs {
  border: solid 1px #d0d0d0;
  padding: 20px;
  border-radius: 10px;
}
.subs-price p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
}
.subs-price {
  justify-content: space-between;
  margin-bottom: 10px;
}
.cancel-opt {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  color: #FE5F55;
  font-weight: 500;
}
.select-plan-opt button.btn {
  background: transparent !important;
  color: #303030 !important;
  border-color: #d0d0d0;
  width: 100%;
  text-align: left;
}
.select-plan-opt .dropdown-toggle::after {
  margin-left: auto !important;
  position: absolute;
  right: 10px !important;
  top: 16px;
  color: #404040 !important;
}
.select-plan-opt .dropdown-menu.show {
  width: 100%;
  padding: 0;
}
.select-plan-opt .dropdown-menu.show a.dropdown-item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #c0c0c0;
  padding: 10px 10px;
}

.select-plan-opt .dropdown-menu.show a.dropdown-item p {
  margin-bottom: 0;
}

.select-plan-opt .dropdown-menu.show a.dropdown-item:last-child {
  border-bottom: 0;
}
.plan-desc {
  max-height: 160px;
  overflow: auto;
}
.plan-desc p {
  margin-bottom: 0;
}
.subscription-conf .modal-header {
  text-align: left;
  width: 100%;
  left: 0;
  padding: 0 15px;
  border-bottom: solid 1px #dee2e6;
  padding-bottom: 10px;
}
.subscription-conf .modal-body {
  margin-top: 65px;
}
.subscription-conf .modal-header .modal-title {
  font-size: 18px;
}
/* Responsive CSS */
@media (max-width: 1550px) {
  .login-form {
    padding: 30px 50px;
  }
  .customer-form-main .login-form {
    padding: 30px 30px;
  }
  .login-cont.customer-form h3 {
    font-size: 48px;
  }
}
@media (max-width: 1366.98px) {
  .customer-form-logo h6 {
    color: #fff;
    font-size: 20px;
    max-width: 70%;
    margin: 25px auto 0;
  }
  .customer-form-bg .form-group {
    margin-bottom: 20px;
  }
  .customer-form-bg .form-control {
    min-height: 40px;
    padding: 8px 15px;
  }
  .login-btn .btn_primary {
    height: 40px;
  }
  .overview-cont {
    padding: 45px 0;
  }
  .pageHeader .photoGrapherOuter .photographerName {
    font-size: 32px;
  }
}
@media (max-width: 1199.98px) {
  .login-cont.customer-form h3 {
    font-size: 32px;
  }
  .login-form {
    padding: 30px 30px;
  }
  .col_5 {
    flex: 0 0 23%;
    max-width: 23%;
    padding: 0 7px;
  }
  .upcoming-job-wrap .row {
    margin: 0 -7px;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .row {
    margin: 0 -7px;
  }
  [class*="col-"] {
    padding: 0 7px;
  }
  .inner-title .nav .nav-link {
    padding: 12px 15px;
    min-width: 160px;
  }
  .btn_primary {
    padding: 10px 20px;
    font-size: 14px;
  }
  .btn_default {
    font-size: 14px;
    padding: 0 15px;
  }
  .uplaod-logo .file_input span img {
    max-width: 24px;
  }
  .login-form {
    justify-content: space-between;
  }
  .pageHeader .photoGrapherOuter .photographerName {
    font-size: 26px;
    margin: 18px 0;
  }
  .formTitle {
    font-size: 20px;
  }
  .formMainOuter {
    margin-top: 36px;
  }
  .pageFooter {
    margin-top: 80px;
  }
  .formMainOuter .formInner {
    margin-top: 32px;
  }
  .pageMainWraper {
    padding: 50px 0;
  }
  .inner-title.setting-navs .nav {
    white-space: nowrap;
    flex-wrap: unset;
  }
  .inner-title.setting-navs nav {
    overflow: auto;
    width: 100%;
  }
  .inner-title.setting-navs nav::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .select-subs {
      margin: 25px 0;
  }
  .pro-tit h5.price {
    font-size: 16px;
  }
  .team-user-dtl p {
    font-size: 12px;
  }

  .team-user-dtl .pro-tit h5 {
    font-size: 15px;
  }
  .mobile-menu {
    display: block;
  }
  .main-panel {
    flex-direction: column;
  }
  .mobile-menu .my-account-wrap {
    margin-bottom: 0;
    border: 0;
    padding: 10px 15px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .mobile-menu .my-account-wrap .user-img {
    margin: 0;
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }
  .my-account-wrap .user-cont a {
    line-height: 1;
  }
  .my-account-wrap .user-cont h5 {
    margin-bottom: 0;
    line-height: 1;
    text-align: left;
  }
  .sidebar-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 111;
    background: #fff;
    padding: 15px 15px;
    box-shadow: 0 -5px 5px rgb(0 0 0 / 15%);
    left: 0;
    height: auto;
  }

  .mobile-menu span.menu {
    display: none;
  }
  .mobile-menu a {
    background: transparent !important;
    padding: 0 !important;
  }
  .mobile-menu .sidebar-menu ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 10px;
  }

  .mobile-menu .menu-ic {
    margin-right: 0;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overview-cont {
    padding-top: 10px;
    padding-bottom: 70px;
  }
  .breadcurm-title {
    display: none;
  }
  .mobile-menu {
    padding-top: 30px;
    height: calc(100% - 30px);
  }
  .upcoming-job-wrap .row {
    margin: 0 -7px;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .upcoming-job-wrap .row .col_5 {
    padding: 0 7px;
    flex: 0 0 23.5%;
    max-width: 23.5%;
  }
  .fm-panel {
    display: none;
  }
  .panel-content {
    flex: 0 0 100%;
    margin: 0;
    max-width: 100%;
  }
  .login-cont {
    padding: 35px 40px;
  }
  .login-form-cont h6 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .login-inner-cont h3 {
    font-size: 22px;
  }

  .login-inner-cont p {
    font-size: 15px;
  }
  .pageHeader .photoGrapherOuter .photographerName {
    font-size: 22px;
    margin: 16px 0;
  }
  .pageHeader .photoGrapherOuter .btnVisitBtn {
    padding: 8px 18px;
  }
  .pageFooter .footerOuter .companyLogo {
    width: 100px;
  }
  .formMainOuter .form-group {
    margin-bottom: 16px;
  }
  .additionalNote {
    padding-top: 16px;
    margin-top: 16px;
  }
}
@media (max-width: 767.98px) {
  .AddnewTeam {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .addNewBtn {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 10px;
  }
  .booking-main-card.booking-card {
    flex-wrap: wrap;
  }
  .login-sec.customer-form-main .container.p-0 {
    padding: 0 15px !important;
  }
  .booking-form-card {
    padding: 15px;
  }
  .form-title h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .form-title p {
    font-size: 14px;
  }
  .plan-inner h5 {
    font-size: 16px;
  }
  .plan-inner ul li {
    font-size: 14px;
  }
  .plan-inner h5.plan-price {
    font-size: 20px;
  }
  .plan-inner ul {
    margin: 25px 0;
  }
  .plan-inner {
    margin-bottom: 15px;
  }
  .single-option label {
    font-size: 13px;
  }
  .additional-opt-price p {
    font-size: 14px;
  }
  .custom-checkbox .checkmark {
    top: 0;
  }
  .additional-option-list {
    padding: 6px 10px;
  }
  .additional-option h4 {
    font-size: 16px;
  }
  .add-hours-sec h4 {
    font-size: 16px;
  }
  .add-hours-sec p {
    font-size: 13px;
  }
  .input-form {
    width: 40px;
  }
  .price-right p {
    font-size: 18px;
  }
  .price-box-left button {
    height: 35px;
    width: 35px;
    font-size: 20px;
  }
  .step-from-btn {
    margin-bottom: 10px;
  }
  .booking-form-inner-cont h3 {
    font-size: 22px;
  }
  .booking-form-inner-cont p {
    font-size: 14px;
    line-height: 1.4;
  }
  label.form-label {
    font-size: 14px;
  }
  .login-inner .row.m-0 {
    flex-direction: column-reverse;
  }
  .login-form {
    justify-content: center;
  }
  .login-form.signup-form {
    height: auto;
  }
  .login-cont {
    height: unset;
    display: none;
  }
  .client-info.setting-inner h5 {
    font-size: 14px;
  }
  .client-info.setting-inner p {
    font-size: 12px;
  }
  .client-info.setting-inner li h5 {
    font-size: 14px;
  }
  .notification-wrap h4 {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .notification-wrap .form-check label.form-check-label {
    font-size: 12px;
  }
  .team-dtl h4 {
    margin-bottom: 8px;
  }
  .event-card-body h3 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .contract_launch h3 {
    font-size: 18px;
  }
  .add-new-wrap {
    position: fixed;
    bottom: 60px;
    z-index: 111;
    right: 15px;
  }
  .add-new-wrap .btn_primary {
    font-size: 0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }
  .add-new-wrap .btn_primary svg {
    margin: 0;
  }
  .inner-title .nav .nav-link {
    min-width: 100px;
    padding: 12px 10px;
    font-size: 14px;
  }
  .inner-title .nav {
    padding: 0;
  }
  .booking-card a {
    flex-direction: column;
  }
  .booking-notification {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
  .booking-left {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .booking-notifi-cont {
    text-align: left;
  }
  span.tag {
    margin-left: 0;
    width: auto;
    border-radius: 5px;
    padding: 5px 10px;
    display: inline-flex;
    justify-content: center;
  }
  .dashboard-card .row {
    margin: 0 -7px;
  }
  .dashboard-card .row [class*="col-"] {
    padding: 0 7px;
  }
  .overview-cont h3 {
    font-size: 26px;
    margin-bottom: 23px;
  }
  .space-sec {
    margin: 20px 0;
  }
  .upcoming-job-wrap .row .col_5 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .upcoming-job-wrap .card .card-body {
    padding: 12px;
  }
  .mobile-menu .sidebar-menu ul {
    max-width: 540px;
    margin: 0 auto;
  }
  .booking-cont h3 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .booking-notifi-cont h5 {
    font-size: 12px;
  }
  .client-dtl h4 {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .client-dtl .card {
    padding: 10px;
  }
  .client-info {
    margin-bottom: 10px;
  }
  .margin-cls {
    padding-top: 15px;
  }
  .client-info h5 {
    font-size: 13px;
  }
  .client-info p {
    font-size: 12px;
  }
  .file_wrap h6 {
    font-size: 13px;
  }
  .file_wrap svg {
    margin-right: 10px;
  }
  .card_header h5 {
    font-size: 15px;
  }
  .card_header p {
    font-size: 13px;
  }
  .card_header {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .file_wrap {
    margin-bottom: 12px;
  }
  .add-new-wrap.generate-contract {
    position: unset;
  }
  .add-new-wrap.generate-contract .btn.btn_primary {
    width: 100%;
    height: unset;
    border-radius: 8px;
    padding: 8px 15px !important;
    font-size: 14px;
  }
  .user-team-img {
    height: 52px;
    width: 52px;
  }
  .team-dtl .client-info h5 {
    font-size: 15px;
  }
  .team-dtl .client-info p {
    font-size: 13px;
    margin: 0;
  }
  .team-dtl .client-info p.price {
    font-size: 14px;
    margin: 0;
  }
  .event-card-body h3 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .event-card-body {
    padding: 12px;
  }
  .event-dtl li h5,
  .event-dtl li h6 {
    font-size: 12px;
  }
  .user-event-header h6 {
    font-size: 14px;
  }
  p.fees {
    font-size: 12px;
  }
  .event-dtl li {
    margin-bottom: 5px;
  }
  .team-dtl .card {
    padding: 12px;
  }
  .enable-packages span {
    font-size: 14px;
  }
  .enable-packages {
    padding: 8.5px 10px;
  }
  .pageHeader .photoGrapherOuter .photographerName {
    font-size: 20px;
    margin: 12px 0;
  }
  .formMainOuter {
    margin-top: 24px;
  }
  .formTitle {
    font-size: 18px;
  }
  .formMainOuter .formInner {
    margin-top: 20px;
  }
  .pageMainWraper {
    padding: 40px 0;
    background: #f2f1f4;
  }
  .btnSubmit,
  .backFillBtn {
    font-size: 14px;
    padding: 10px 24px;
    border-radius: 6px;
  }
  .pageFooter {
    margin-top: 60px;
  }
  .stepsOuter .step-from-btn {
    height: unset;
  }
  .login-form-cont img {
    width: 160px;
  }
  .login-form-cont h6 {
    font-size: 16px;
  }

}
@media (max-width: 575.98px) {
  .price-box {
    padding: 12px;
    border-radius: 12px;
}

.price-box ul li {
    font-size: 13px;
}

.client-dtl.additional-note .file_wrap {
    font-size: 14px;
}

.additional-note a {
    font-size: 14px;
}

.file_wrap h6, .file_wrap a {
    font-size: 14px;
}

.contract-box {
    padding: 12px;
}

.customModal .modal-content {
  border-radius: 15px;
}

.modal-header {
  right: 0;
  top: 0px;
}
.breadcurm-title {flex-wrap: wrap;}

.contract-box {
    padding: 12px;
}

.file_wrap {
    flex-wrap: wrap;
}

.file_wrap .btn_default {
    margin-left: 0 !important;
    margin-top: 10px;
}

.overview-cont h3 {
    font-size: 18px;
}
.btn_primary {
    padding: 5px 10px;
    font-size: 12px;
}
  .file_wrap .btn_default {
      padding: 10px 10px;
      font-size: 12px;
      width: 180px;
  }

  .contract-box {
      padding: 12px;
  }

  .projectDetail .client-dtl .card {
      padding: 8px 12px;
  }

  .projectDetail .client-dtl .card .session-header h5 {
      font-size: 16px;
  }

  .time-right p {
      font-size: 12px;
  }

  .projectDetail .location-wrap p, .projectDetail .location-wrap p a {
      font-size: 12px !important;
  }

  .location-wrap {
      align-items: start;
  }

  .map-ic {margin-top: 3px;}

  .booking-dtl-cls .row.g-5 {
      --bs-gutter-y: 1rem !important;
  --bs-gutter-x: 1rem;
  }

  .projectDetail .client-dtl h4 img {
      margin-left: 5px;
  }

  .bookingDetailBody .details .clientName,
  .bookingDetailBody .details .address,
  .bookingDetailBody .details .clientContact .contact,
  .bookingDetailBody .details .contractDec .contractPera {
    font-size: 12px;
  }
  .projectDetail.project-contract-inner .bookingDetailBody {
    padding: 15px !important;
  }
  .uploadeFileOuter .uploadFileHeader .fileTitle {
    font-size: 14px;
    line-height: 18px;
  }
  .uploadeFileOuter .fileName {
    font-size: 14px;
    line-height: 20px;
  }
  .deletIcon {
    width: 20px;
  }
  .booking-link h5 {
    font-size: 14px;
  }
  .booking-link p {
    font-size: 12px;
    line-height: 1.3;
  }
  .booking-link {
    padding-right: 10px;
  }
  .autoresponder-title h5 {
    font-size: 14px;
  }
  .autoresponder-wrap p {
    font-size: 12px;
  }
  .filter-btn input {
    padding: 7px 10px;
    font-size: 14px;
  }
  .add-new-wrap.generate-contract {
    margin-left: 0;
    margin-top: 10px;
  }
  .booking-cont p {
    font-size: 12px;
  }
  .notifi-dot {
    margin-left: auto;
  }
  .enable-packages {
    margin-top: 10px;
  }
  .inner-title {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .filter-wrap {
    margin-top: 10px;
  }
  .add-new-wrap.generate-contract .btn.btn_primary {
    padding: 5px 8px !important;
    font-size: 12px;
  }
  .client-info ul li {
    font-size: 12px;
    line-height: 1;
  }
  .signle-client-info {
    padding-right: 10px;
  }
  .recieved-today ul {
    padding-left: 7px;
    gap: 7px;
  }
  .client-info h5 {
    font-size: 12px;
    margin-bottom: 0;
  }
  .recieved-today ul li a img {
    max-width: 15px;
    height: auto;
  }
  .mobile-menu .sidebar-menu ul {
    padding: 0;
  }
  .upcoming-job-wrap .row .col_5 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .card-body {
    padding: 12px;
  }
  .card.booking-bg p {
    font-size: 12px;
  }
  .card.booking-bg h3 {
    font-size: 20px;
  }
  .card.inquiries-bg p {
    font-size: 12px;
  }
  .card.inquiries-bg h3 {
    font-size: 20px;
  }
  .upcoming-job-wrap .row .col_5 {
    flex: 0 0 56%;
    max-width: 56%;
  }
  .job-topbar {
    padding-bottom: 10px;
  }
  .main-title h5 {
    font-size: 16px;
    line-height: 1;
  }
  .date-cls {
    padding: 5px 10px;
    margin-right: 10px;
  }
  .date-cls p {
    font-size: 12px;
  }
  .event-dtl h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .event-dtl p {
    font-size: 12px;
    margin-top: 0;
  }
  .job-topbar h5 {
    font-size: 14px;
  }
  .job-bottom-bar h6 {
    font-size: 12px;
  }
  .job-bottom-bar p {
    font-size: 12px;
  }
  .pageHeader .photoGrapherOuter .proImgOuter {
    width: 60px;
    height: 60px;
  }
  .pageHeader .photoGrapherOuter .photographerName {
    font-size: 18px;
  }
  .pageHeader .photoGrapherOuter .btnVisitBtn {
    padding: 8px 14px;
  }
  .formTitle {
    font-size: 16px;
  }
  .formMainOuter label,
  .stepsOuter .custom-checkbox {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 6px !important;
  }
  .formMainOuter .form-control {
    padding: 7px 16px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.5;
  }
  .login-form-cont img {
    width: 140px;
  }
  .login-form-cont h6 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 15px;
  }
  .sign-up-free .light_btn {
    padding: 8px 24px;
    font-size: 14px;
  }
  .login-form {
    padding: 20px 16px;
  }
  .booking-card {
    padding: 10px 10px;
  }
  .notifi-dot {
    padding-left: 0;
  }
}
.content {
  white-space: normal;
  word-break: break-all;
}
.ql-editor {
  height: calc(300px - 30px);
  display: flex;
  flex-direction: column;
}